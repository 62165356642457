// extracted by mini-css-extract-plugin
export var nav = "Navbar-module--nav--2Aqzw";
export var list = "Navbar-module--list--2GS4m";
export var navItem = "Navbar-module--navItem--1mo1M";
export var gray = "Navbar-module--gray--2_Dlu";
export var navItemCurrent = "Navbar-module--navItemCurrent--2ujXH";
export var navItemLinkGray = "Navbar-module--navItemLinkGray--2FKfs";
export var navItemLinkBlack = "Navbar-module--navItemLinkBlack--Fpv7Z";
export var navItemCurrectProject = "Navbar-module--navItemCurrectProject--1NXMf";
export var navItemLink = "Navbar-module--navItemLink--3ON2H";
export var btn = "Navbar-module--btn--vBDMW";